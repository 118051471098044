import { Injectable } from "@angular/core";
import { Http } from "@angular/http";

@Injectable({
  providedIn: "root",
})
export class StrikeOffFormService {
  constructor(private http: Http) {}

  // post("/api/contacts")
  submitForm(newForm: any): Promise<void> {
    return this.http
      .post("/api/submit-strikeoff-form", newForm)
      .toPromise()
      .then((response) => response.json())
      .catch(this.handleError);
  }

  private handleError(error: any) {
    let errMsg = error.message ? error.message : "";
    error.status ? `${error.status} - ${error.statusText}` : "Server error";
    console.error(errMsg); // log to console instead
  }
}
