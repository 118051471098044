import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-temp-contact-us",
  templateUrl: "./temp-contact-us.component.html",
  styleUrls: ["./temp-contact-us.component.css"],
})
export class TempContactUsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
