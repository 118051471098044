import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { StrikeOffFormService } from "./strike-off-form.service";
// import { ValidationService } from 'app/validation.service';

@Component({
  selector: "app-strike-off-form",
  templateUrl: "./strike-off-form.component.html",
  styleUrls: ["./strike-off-form.component.css"],
})
export class StrikeOffFormComponent implements OnInit {
  strikeOffForm: any;
  submitClicked: boolean = false;
  errors: boolean = false;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private strikeOfFormService: StrikeOffFormService
  ) {
    this.strikeOffForm = this.formBuilder.group({
      serv: ["", Validators.required],
      person: ["", Validators.required],
      fullName: ["", Validators.required],
      address: [""],
      phone: [""],
      emailAddress: ["", Validators.required],
      authority: [""],
      companyName: ["", Validators.required],
      croNumber: ["", Validators.required],
      nameChanged: [""],
      previousCompanyName: [""],
      tradingAs: [""],
      registeredBusName: [""],
      registeredAddress1: [""],
      registeredAddress2: [""],
      registeredRegion: [""],
      registeredCity: [""],
      registeredPostalCode: [""],
      registeredCountry: [""],

      prevRegisterOffice: [""],

      prevAddress1: [""],
      prevAddress2: [""],
      prevRegion: [""],
      prevCity: [""],
      prevPostalCode: [""],
      prevCountry: [""],

      difAddress: [""],

      busAddress1: [""],
      busAddress2: [""],
      busRegion: [""],
      busCity: [""],
      busPostalCode: [""],
      busCountry: [""],

      previouslyTraded: [""],
      ceasedTradingDate: [""],
      letterOfNoObjection: [""],
      letterAssistance: [""],
      taxesPaid: [""],
      registeredForTaxes: [""],
      companyTaxNumber: [""],
      deRegistered: [""],
      taxReturnSubmitted: [""],

      companyNoLongerTrading: [""],
      returnsUpToDate: [""],
      assetsExceed150: [""],
      liabilitiesExceed150: [""],
      ongoingLitigation: [""],

      strikeOffByFirstName: [""],
      strikeOffByLastName: [""],
      strikeOffBy: [""],
      tandcAgreed: [""],
    });
  }

  ngOnInit() {}

  submitForm() {
    this.submitClicked = true;
    if (this.strikeOffForm.valid) {
      this.errors = false;
      this.submitted = true;
      this.strikeOfFormService.submitForm(this.strikeOffForm.value);
    } else {
      this.errors = true;
    }
  }
}
