import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { StrikeOffFormComponent } from "./strike-off-form/strike-off-form.component";
import { StrikeOffAdvertisementComponent } from "./strike-off-advertisement/strike-off-advertisement.component";
import { NonTradingCompanyComponent } from "./non-trading-company/non-trading-company.component";
import { FullStrikeOffComponent } from "./full-strike-off/full-strike-off.component";
import { FaqComponent } from "./faq/faq.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { LiquidationsComponent } from "./liquidations/liquidations.component";
import { TempContactUsComponent } from "./temp-contact-us/temp-contact-us.component";

const routes: Routes = [
  { path: "contact-us.html", component: ContactUsComponent },
  { path: "faq.html", component: FaqComponent },
  { path: "full-strike-off.html", component: FullStrikeOffComponent },
  { path: "non-trading-company.html", component: NonTradingCompanyComponent },
  {
    path: "strike-off-advertisement.html",
    component: StrikeOffAdvertisementComponent,
  },
  // { path: "temp-strike-off-form.html", component: StrikeOffFormComponent },
  { path: "strike-off-form.html", component: StrikeOffFormComponent },
  { path: "termsconditions.html", component: TermsAndConditionsComponent },
  { path: "home", component: HomeComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
