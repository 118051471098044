import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HomeComponent } from "./home/home.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { FaqComponent } from "./faq/faq.component";
import { StrikeOffFormComponent } from "./strike-off-form/strike-off-form.component";
import { FullStrikeOffComponent } from "./full-strike-off/full-strike-off.component";
import { NonTradingCompanyComponent } from "./non-trading-company/non-trading-company.component";
import { StrikeOffAdvertisementComponent } from "./strike-off-advertisement/strike-off-advertisement.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { LiquidationsComponent } from "./liquidations/liquidations.component";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { TempContactUsComponent } from "./temp-contact-us/temp-contact-us.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactUsComponent,
    TempContactUsComponent,
    FaqComponent,
    StrikeOffFormComponent,
    FullStrikeOffComponent,
    NonTradingCompanyComponent,
    StrikeOffAdvertisementComponent,
    TermsAndConditionsComponent,
    LiquidationsComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, ReactiveFormsModule, HttpModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
